$(window).on("load", function () {
	// Ordenação dos itens
	// console.log($('.listWithHandle'));
	if ($('.listWithHandle').length) {
		$('.listWithHandle').each(function(index) {
			var element = document.getElementById($(this).attr("id"));
			// console.log($(this).attr("id"));
			Sortable.create(element, {
				handle: '.glyphicon-move',
				animation: 150,
				onUpdate: function (evt/**Event*/){
					var item = evt.item; // the current dragged HTMLElement
					var controller = $(evt.to).attr("data-controller");
					var module = $(evt.to).attr("data-module");
					var order = $(evt.to).find("input").serializeArray();
	
					$("#loading").show();
					$.post(URL_BASE+module+controller+"/order", {order:order}, function(theResponse){
						$("#loading").hide();
	
						$(evt.to).find("tr").each(function (key, elem) {
							$(this).find(".ln-order").html(key+1);
						});
					});
				}
			});
		});
		
	}
});